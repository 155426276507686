import '@babel/polyfill';
jQuery(function ($) {
    console.log('test.jsが読みこまれた');
    $('body').addClass('jquery-add-class');
});
document.addEventListener("DOMContentLoaded", function () {
    const navItems = document.querySelectorAll('.js-gnav__item-have-child');
    navItems.forEach(item => {
        const childWrap = item.nextElementSibling;
        item.addEventListener('mouseenter', function () {
            if (childWrap && childWrap.classList.contains('p-gnav__item-child-wrap')) {
                childWrap.classList.add('p-gnav__item-child-wrap--active');
            }
        });
        item.addEventListener('mouseleave', function () {
            if (childWrap && childWrap.classList.contains('p-gnav__item-child-wrap')) {
                setTimeout(() => {
                    if (!childWrap.matches(':hover') && !item.matches(':hover')) {
                        childWrap.classList.remove('p-gnav__item-child-wrap--active');
                    }
                }, 100);
            }
        });
        if (childWrap) {
            childWrap.addEventListener('mouseleave', function () {
                setTimeout(() => {
                    if (!childWrap.matches(':hover') && !item.matches(':hover')) {
                        childWrap.classList.remove('p-gnav__item-child-wrap--active');
                    }
                }, 100);
            });
        }
    });
});
document.addEventListener("DOMContentLoaded", function () {
    var diagonalLine = document.querySelector(".l-home-kv__diagonal-blue-background");
    var diagonalLine2 = document.querySelector(".l-home-kv__diagonal-blue-background2");
    var title = document.querySelector(".s-home-kv-title");
    diagonalLine.classList.add("active");
    setTimeout(function () {
        diagonalLine2.classList.add("active");
    }, 500);
    setTimeout(function () {
        title.classList.add("active");
    }, 1000);
});
const swiper = new Swiper(".swiper", {
    loop: false,
    slidesPerView: 1.3,
    spaceBetween: 16,
    speed: 600,
    breakpoints: {
        599: {
            slidesPerView: 2.3,
        },
        1024: {
            slidesPerView: 3.3,
        },
    },
});
document.addEventListener('DOMContentLoaded', function () {
    var video = document.getElementById('videoElement');
    var image = document.getElementById('loadingImg');
    var mobileVideoSrc = 'assets/movie/mobile-movie-sp.mp4';
    video.oncanplaythrough = function () {
        image.style.display = 'none';
        video.style.opacity = '1';
    };
    if (window.matchMedia("(max-width: 768px)").matches) {
        video.src = 'assets/movie/movie-0707-sp.mp4';
    }
    else {
        video.src = 'assets/movie/movie-0707.mp4';
    }
});
